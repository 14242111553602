import * as React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";

function WalletDetails() {
  return (
      <div>
        <h1>Wallet</h1>
      </div>
  );
}

export default WalletDetails;
