import {
  connect,
  Contract,
  keyStores,
  WalletConnection,
  nearAPI,
} from "near-api-js";
import { async } from "regenerator-runtime";
import getConfig from "./config";

const nearConfig = getConfig("development");

console.log(`Using config: ${JSON.stringify(nearConfig, null, 2)}`);

// Initialize contract & set global variables
export async function initContract() {
  // Initialize connection to the NEAR testnet
  const near = await connect(
    Object.assign(
      { deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } },
      nearConfig
    )
  );

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  window.walletConnection = new WalletConnection(near);

  // Getting the Account ID. If still unauthorized, it's just empty string
  window.accountId = window.walletConnection.getAccountId();

  // Initializing our contract APIs by contract name and configuration
  window.contract = await new Contract(
    window.walletConnection.account(),
    nearConfig.contractName,
    {
      // View methods are read only. They don't modify the state, but usually return some value.
      viewMethods: [
        "getGreeting",
        "getProposals",
        "historyYes",
        "historyNo",
        "getBalance",
        "getStake",
        "getTime",
        "getStakers",
        "get_num",
        "checkReward",
        "getReward",
      ],
      // Change methods can modify the state. But you don't receive the returned value when called.
      changeMethods: [
        "setGreeting",
        "addProposal",
        "vote",
        "transferCall",
        "stake",
        "balanceOf",
        "rewardFeeCalculation",
        "transferFromStakingPool",
        "transferFromRewardPool",
        "unstake",
        "resetStake",
      ],
    }
  );
  window.contractPotato = await new Contract(
    window.walletConnection.account(),
    "lights.testnet",
    {
      viewMethods: ["ft_metadata"],
      changeMethods: [],
    }
  );
}

export function logout() {
  window.walletConnection.signOut();
  // reload page
  window.location.replace(window.location.origin + window.location.pathname);
}

export function login() {
  // Allow the current app to make calls to the specified contract on the
  // user's behalf.
  // This works by creating a new access key for the user's account and storing
  // the private key in localStorage.
  window.walletConnection.requestSignIn(nearConfig.contractName);
}
